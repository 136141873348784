import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import HeaderIntroBlock from '../../components/Blocks/HeaderIntroBlock'
import HowItWorkV2Block from '../../components/Blocks/HowItWorkV2Block'
import { navigate } from '@reach/router'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const HealthcareMarketers = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const pageName = 'Healthcare Organizations'
     const breadcrumbItems = [
          {
               name: 'Outcomes',
               url: `${'outcomes'}`,
          },
          {
               name: `${pageName}`,
          },
     ]
     const headerIntroContent = {
          subtitle: 'Healthcare Marketers',
          title: 'HIPAA Compliant Marketing Analytics & Conversion Tracking',
          description: 'Get the Data You Need to Prove Results',
          // substring: "Covered entities can maintain the data they need to measure and improve marketing while remaining HIPAA compliant. ",
     }
     const headerIntroCard = {
          description: `<span>ListenLayer is uniquely positioned to support healthcare organizations' need for marketing analytics and conversion tracking while respecting the privacy of your users and patients.</span>`,
          fName: 'Zachary W. Randall',
          fPosition: 'Founder & CEO',
          imageURL: '/images/hero-image.png',
     }

     const [showSchedule, setShowSchedule] = useState(false)

     const handleShowSchedule = () => {
          setShowSchedule(true)
          if (window.location.href.includes('&')) {
               let newUrl = window.location.href + '&view-demo=1'
               window.history.replaceState(null, null, newUrl)
          } else {
               window.history.replaceState(null, null, '?view-demo=1')
          }
     }

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               if (window.location.pathname !== '/outcomes/hipaa-marketing-data/') {
                    navigate(`/outcomes/hipaa-marketing-data/`)
               }

               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     const data = [
          {
               title: 'BAA-Covered Analytics',
               content:
                    'ListenLayer is in the process of developing a BAA that will allow you to utilize the platform freely for marketing analytics and data distribution. Until then, you can use the platform in anonymous mode and gain most of the same benefits.',
          },
          {
               title: 'Centralize Definitions',
               content:
                    'Centralize how you define conversions and KPIs used across your data destinations like Google Ads, GA4, LinkedIn, Facebook, Microsoft Ads, Twitter, and more...',
          },
          {
               title: '3rd Party Conversions for Healthcare',
               content:
                    'Maintain control over the data you send to organizations like Google, Meta (Facebook), and more by filtering our sensitive Health (HI) and Personal (PI) data in your conversions tracking. ',
          },
          {
               title: 'Consent & Compliance Management',
               content:
                    'ListenLayer is consent-first. The core architecture of how we identify users and store data focuses on enabling compliance with ever-changing privacy laws. ListenLayer can replace, or augment, your current CMP tool for simplicity and savings.',
          },
          {
               title: 'Clean Up Google Tag Manager',
               content:
                    'Implement a clean, simple, and organized measurement strategy that reduces the number of tags, triggers, and custom scripts you need to manage.',
          },
          {
               title: 'Non-Technical Implementation',
               content:
                    'Marketing can implement ListenLayer without requiring IT and development resources. Even advanced tracking and data extraction can be done with no programming skills at all!',
          },
          {
               title: 'No-code, Fully Customizable',
               content:
                    'ListenLayer automates all data generation and collection for marketing, including things that would typically require custom code. Your marketing team can write powerful, visual rules that enrich and segment their data as needed - no programming skills required.',
          },
          {
               title: '100% First Party, Server Side',
               content:
                    'Implement server side tracking with the flip of a switch. Utilize your own sub domain to achieve a 100% first-party data strategy that boosts your website speed and data accuracy.',
          },
          {
               title: 'Security-first Architecture',
               content:
                    'ListenLayer is currently pursuing SOC2, Type 2 compliance and executes regular security and penetration testing. Our architecture supports GDPR, CCPA/CPRA, and other regulatory requirements.',
          },
          {
               title: 'The Future of Cookieless',
               content: 'ListenLayer offers a cookieless architecture to future-proof your measurement strategy. The future is already here!',
          },
          {
               title: 'Google Analytics Alternative',
               content:
                    "Break away from Google's stack while receiving even better data and powering what marketing requires. Your data belongs to you (not Google), take it back!",
          },
          {
               title: 'World Class Support',
               content:
                    'Your marketing team can lean on our world class support to fill knowledge gaps and ensure a successful implementation - while minimizing time investment from IT and development resources!',
          },
     ]

     const howItWork = {
          openingContent: `
               <p>Healthcare marketers can still gain insights, measure results, and improve performance with marketing analytics and 3rd party conversion tracking configured to protect patient privacy.</p>
          `,
          steps: [
               {
                    icon: `<?xml version="1.0" encoding="utf-8"?>
                    <svg width="23px" height="31px" viewBox="0 0 23 31" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                         <g id="iconfinder_electronic_29_4658126">
                         <path d="M12.718 1L10.282 1C5.15569 1 1 5.09915 1 10.1557L1 20.8443C1 25.9008 5.15569 30 10.282 30L12.7075 30C15.1711 30.0027 17.5347 29.0393 19.2777 27.322C21.0206 25.6047 22 23.2743 22 20.8443L22 10.1557C22 7.72747 21.0221 5.39868 19.2814 3.68165C17.5407 1.96462 15.1797 1 12.718 1ZM11.5 24.5832C10.9201 24.5832 10.45 24.1195 10.45 23.5475C10.45 22.9755 10.9201 22.5118 11.5 22.5118C12.0799 22.5118 12.55 22.9755 12.55 23.5475C12.55 24.1195 12.0799 24.5832 11.5 24.5832Z" id="Shape" fill="#18222B" stroke="none" />
                         <path d="M12.7127 31L10.2873 31C4.61054 30.9886 0.011503 26.4429 0 20.832L0 10.168C0.011503 4.55709 4.61054 0.0113697 10.2873 0L12.7023 0C18.3831 0.00568313 22.9885 4.55306 23 10.168L23 20.832C22.9885 26.4429 18.3895 30.9886 12.7127 31ZM10.2873 2.06667C5.76293 2.07236 2.09667 5.69612 2.09091 10.168L2.09091 20.832C2.09667 25.3039 5.76293 28.9276 10.2873 28.9333L12.7023 28.9333C17.2307 28.9333 20.9033 25.3079 20.9091 20.832L20.9091 10.168C20.9033 5.69612 17.2371 2.07236 12.7127 2.06667L10.2873 2.06667Z" id="Shape" fill="#18222B" stroke="none" />
                         <path d="M13.6 13.4286C13.6 14.5726 12.6598 15.5 11.5 15.5C10.3402 15.5 9.4 14.5726 9.4 13.4286L9.4 7.21428C9.4 6.07027 10.3402 5.14286 11.5 5.14286C12.6598 5.14286 13.6 6.07027 13.6 7.21428L13.6 13.4286Z" id="Path" fill="#FFC107" stroke="none" />
                         </g>
                    </svg>`,
                    title: 'ListenLayer automatically',
                    description: 'generates powerful data from your website and marketing activities.',
               },
               {
                    icon: `<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" width="33px" height="32px" viewBox="0 0 33 32" version="1.1">
                         <g id="iconfinder_415-Data_Check_2124501" transform="translate(1 1)">
                         <path d="M15.2459 10C23.666 10 30.4918 7.76142 30.4918 5C30.4918 2.23858 23.666 0 15.2459 0C6.82582 0 0 2.23858 0 5C0 7.76142 6.82582 10 15.2459 10Z" id="Oval" fill="none" fill-rule="evenodd" stroke="#18222B" stroke-width="2" stroke-linejoin="round"></path>
                         <path d="M11.1803 4.82C4.73639 4.24 0 2.3 0 0" transform="translate(0 12)" id="Path" fill="none" fill-rule="evenodd" stroke="#18222B" stroke-width="2" stroke-linejoin="round"></path>
                         <path d="M8.13115 18.42C3.29311 17.58 0 15.92 0 14L0 0" transform="translate(0 5)" id="Path" fill="none" fill-rule="evenodd" stroke="#18222B" stroke-width="2" stroke-linejoin="round"></path>
                         <path d="M0.508197 0L0.508197 5" transform="translate(29.983398 5)" id="Path" fill="none" fill-rule="evenodd" stroke="#18222B" stroke-width="2" stroke-linejoin="round"></path>
                         <path d="M8.13115 16C12.6219 16 16.2623 12.4183 16.2623 8C16.2623 3.58172 12.6219 0 8.13115 0C3.64044 0 0 3.58172 0 8C0 12.4183 3.64044 16 8.13115 16Z" transform="translate(14.229492 14)" id="Oval" fill="none" fill-rule="evenodd" stroke="#4DBD74" stroke-width="2" stroke-linejoin="round"></path>
                         <path d="M0 4L2.03279 6L8.13115 0" transform="translate(18.294922 19)" id="Path" fill="none" fill-rule="evenodd" stroke="#4DBD74" stroke-width="2" stroke-linejoin="round"></path>
                         </g>
                    </svg>`,
                    title: 'Centralize',
                    description: 'how you define conversions and KPIs within ListenLayer, under the protection of a BAA.',
               },
               {
                    icon: `<?xml version="1.0" encoding="utf-8"?>
                    <svg width="30.399902px" height="23.200195px" viewBox="0 0 30.399902 23.200195" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group-2">
                         <path d="M29.2 2.3999C29.8628 2.3999 30.4 2.93707 30.4 3.5999L30.4 3.5999C30.4 4.26273 29.8628 4.7999 29.2 4.7999L1.2 4.7999C0.537168 4.7999 0 4.26273 0 3.5999L0 3.5999C0 2.93707 0.537168 2.3999 1.2 2.3999L29.2 2.3999Z" id="Rectangle" fill="#18222B" stroke="none" />
                         <path d="M29.2 10.3999C29.8628 10.3999 30.4 10.9371 30.4 11.5999L30.4 11.5999C30.4 12.2627 29.8628 12.7999 29.2 12.7999L1.2 12.7999C0.537168 12.7999 0 12.2627 0 11.5999L0 11.5999C0 10.9371 0.537168 10.3999 1.2 10.3999L29.2 10.3999Z" id="Rectangle-Copy-34" fill="#18222B" stroke="none" />
                         <path d="M29.2 18.3999C29.8628 18.3999 30.4 18.9371 30.4 19.5999L30.4 19.5999C30.4 20.2627 29.8628 20.7999 29.2 20.7999L1.2 20.7999C0.537168 20.7999 0 20.2627 0 19.5999L0 19.5999C0 18.9371 0.537168 18.3999 1.2 18.3999L29.2 18.3999Z" id="Rectangle-Copy-35" fill="#18222B" stroke="none" />
                         <g id="Group" transform="translate(18.399902 0)">
                         <path d="M2 0C3.10472 0 4 0.89528 4 2L4 5.2C4 6.30472 3.10472 7.2 2 7.2L2 7.2C0.89528 7.2 0 6.30472 0 5.2L0 2C0 0.89528 0.89528 0 2 0Z" id="Rectangle" fill="#20A8D8" stroke="none" />
                         </g>
                         <path d="M9.19995 8C10.3047 8 11.2 8.89528 11.2 10L11.2 13.2C11.2 14.3047 10.3047 15.2 9.19995 15.2L9.19995 15.2C8.09523 15.2 7.19995 14.3047 7.19995 13.2L7.19995 10C7.19995 8.89528 8.09523 8 9.19995 8Z" id="Rectangle-Copy-36" fill="#FFC107" stroke="none" />
                         <path d="M18 16C19.1047 16 20 16.8953 20 18L20 21.2C20 22.3047 19.1047 23.2 18 23.2L18 23.2C16.8953 23.2 16 22.3047 16 21.2L16 18C16 16.8953 16.8953 16 18 16Z" id="Rectangle-Copy-37" fill="#20A8D8" stroke="none" />
                    </g>
                    </svg>`,
                    title: 'Data cleansing',
                    description: 'occurs between ListenLayer and 3rd party tools where you use the data (like Google and Facebook).',
               },
               {
                    icon: `<?xml version="1.0" encoding="utf-8"?>
                    <svg width="30.399902px" height="23.200195px" viewBox="0 0 30.399902 23.200195" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                         <g id="Group-2">
                         <path d="M29.2 2.3999C29.8628 2.3999 30.4 2.93707 30.4 3.5999L30.4 3.5999C30.4 4.26273 29.8628 4.7999 29.2 4.7999L1.2 4.7999C0.537168 4.7999 0 4.26273 0 3.5999L0 3.5999C0 2.93707 0.537168 2.3999 1.2 2.3999L29.2 2.3999Z" id="Rectangle" fill="#18222B" stroke="none" />
                         <path d="M29.2 10.3999C29.8628 10.3999 30.4 10.9371 30.4 11.5999L30.4 11.5999C30.4 12.2627 29.8628 12.7999 29.2 12.7999L1.2 12.7999C0.537168 12.7999 0 12.2627 0 11.5999L0 11.5999C0 10.9371 0.537168 10.3999 1.2 10.3999L29.2 10.3999Z" id="Rectangle-Copy-34" fill="#18222B" stroke="none" />
                         <path d="M29.2 18.3999C29.8628 18.3999 30.4 18.9371 30.4 19.5999L30.4 19.5999C30.4 20.2627 29.8628 20.7999 29.2 20.7999L1.2 20.7999C0.537168 20.7999 0 20.2627 0 19.5999L0 19.5999C0 18.9371 0.537168 18.3999 1.2 18.3999L29.2 18.3999Z" id="Rectangle-Copy-35" fill="#18222B" stroke="none" />
                         <g id="Group" transform="translate(18.399902 0)">
                              <path d="M2 0C3.10472 0 4 0.89528 4 2L4 5.2C4 6.30472 3.10472 7.2 2 7.2L2 7.2C0.89528 7.2 0 6.30472 0 5.2L0 2C0 0.89528 0.89528 0 2 0Z" id="Rectangle" fill="#20A8D8" stroke="none" />
                         </g>
                         <path d="M9.19995 8C10.3047 8 11.2 8.89528 11.2 10L11.2 13.2C11.2 14.3047 10.3047 15.2 9.19995 15.2L9.19995 15.2C8.09523 15.2 7.19995 14.3047 7.19995 13.2L7.19995 10C7.19995 8.89528 8.09523 8 9.19995 8Z" id="Rectangle-Copy-36" fill="#FFC107" stroke="none" />
                         <path d="M18 16C19.1047 16 20 16.8953 20 18L20 21.2C20 22.3047 19.1047 23.2 18 23.2L18 23.2C16.8953 23.2 16 22.3047 16 21.2L16 18C16 16.8953 16.8953 16 18 16Z" id="Rectangle-Copy-37" fill="#20A8D8" stroke="none" />
                         </g>
                    </svg>`,
                    title: 'Compliant analytics',
                    description: 'can be found directly inside ListenLayer, with more data than you had before.',
               },
               {
                    icon: `<?xml version="1.0" encoding="utf-8"?>
                    <svg width="30.399902px" height="23.200195px" viewBox="0 0 30.399902 23.200195" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group-2">
                         <path d="M29.2 2.3999C29.8628 2.3999 30.4 2.93707 30.4 3.5999L30.4 3.5999C30.4 4.26273 29.8628 4.7999 29.2 4.7999L1.2 4.7999C0.537168 4.7999 0 4.26273 0 3.5999L0 3.5999C0 2.93707 0.537168 2.3999 1.2 2.3999L29.2 2.3999Z" id="Rectangle" fill="#18222B" stroke="none" />
                         <path d="M29.2 10.3999C29.8628 10.3999 30.4 10.9371 30.4 11.5999L30.4 11.5999C30.4 12.2627 29.8628 12.7999 29.2 12.7999L1.2 12.7999C0.537168 12.7999 0 12.2627 0 11.5999L0 11.5999C0 10.9371 0.537168 10.3999 1.2 10.3999L29.2 10.3999Z" id="Rectangle-Copy-34" fill="#18222B" stroke="none" />
                         <path d="M29.2 18.3999C29.8628 18.3999 30.4 18.9371 30.4 19.5999L30.4 19.5999C30.4 20.2627 29.8628 20.7999 29.2 20.7999L1.2 20.7999C0.537168 20.7999 0 20.2627 0 19.5999L0 19.5999C0 18.9371 0.537168 18.3999 1.2 18.3999L29.2 18.3999Z" id="Rectangle-Copy-35" fill="#18222B" stroke="none" />
                         <g id="Group" transform="translate(18.399902 0)">
                         <path d="M2 0C3.10472 0 4 0.89528 4 2L4 5.2C4 6.30472 3.10472 7.2 2 7.2L2 7.2C0.89528 7.2 0 6.30472 0 5.2L0 2C0 0.89528 0.89528 0 2 0Z" id="Rectangle" fill="#20A8D8" stroke="none" />
                         </g>
                         <path d="M9.19995 8C10.3047 8 11.2 8.89528 11.2 10L11.2 13.2C11.2 14.3047 10.3047 15.2 9.19995 15.2L9.19995 15.2C8.09523 15.2 7.19995 14.3047 7.19995 13.2L7.19995 10C7.19995 8.89528 8.09523 8 9.19995 8Z" id="Rectangle-Copy-36" fill="#FFC107" stroke="none" />
                         <path d="M18 16C19.1047 16 20 16.8953 20 18L20 21.2C20 22.3047 19.1047 23.2 18 23.2L18 23.2C16.8953 23.2 16 22.3047 16 21.2L16 18C16 16.8953 16.8953 16 18 16Z" id="Rectangle-Copy-37" fill="#20A8D8" stroke="none" />
                    </g>
                    </svg>`,
                    title: 'Gain Insights',
                    description: 'to improve your marketing results while respecting patient privacy.',
               },
          ],
     }

     return (
          <Layout>
               <SEO canonical={canonical} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <HeaderIntroBlock headerIntroContent={headerIntroContent} headerIntroCard={headerIntroCard} />
               <section>
                    <div className="container">
                         <div className="wall-of-text">
                              <p>
                                   <span>
                                        Recent changes to the interpretation of marketing identifiers within a healthcare context have many marketers
                                        losing access to the critical data they need to succeed. ListenLayer has a solution.
                                   </span>
                              </p>
                         </div>
                    </div>
               </section>
               <section>
                    <div className="container">
                         <div class="headline-bodytext-wrapper bodytext-width-large how-it-work m-0">
                              <h3>How does it work?</h3>
                         </div>
                         <HowItWorkV2Block
                              steps={howItWork.steps}
                              openingContent={howItWork.openingContent}
                              explainContent={howItWork.explainContent}
                         />
                    </div>
               </section>

               <section>
                    <div className="section-ready">
                         <div className="container">
                              <div className=" section-ready-box">
                                   <div className="title">
                                        <h3>Schedule a Healthcare Analytics Demo</h3>
                                        <p>
                                             Learn about our HIPAA compliant analytics and 3rd party conversion tracking solutions in detail. We'll
                                             customize a demo for your needs.
                                        </p>
                                   </div>
                                   <div className="button-wrapper">
                                        <button
                                             className="button"
                                             to={'#'}
                                             style={{ padding: '21px 28px', fontSize: '15px' }}
                                             onClick={() => handleShowSchedule()}
                                        >
                                             Schedule Demo
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>

               <section className="platform-step-card">
                    <div className="container">
                         <div class="headline-bodytext-wrapper bodytext-width-large m-0">
                              <h3>Features that Healthcare Marketers Love</h3>
                         </div>
                         <div className="box-card-step">
                              <div className="row">
                                   {data &&
                                        data.map(item => (
                                             <div className="col-md-6 col-lg-6 mb-6 mt-3">
                                                  <div className="card-step h-100">
                                                       <div className="card-body mb-0">
                                                            <h3>{item.title}</h3>
                                                            <p className="card-desc">{item.content}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                              </div>
                         </div>
                    </div>
               </section>

               <section>
                    <div className="section-ready spacing">
                         <div className="container">
                              <div className=" section-ready-box">
                                   <div className="title">
                                        <h3>Schedule a Healthcare Analytics Demo</h3>
                                        <p>
                                             Learn about our HIPAA compliant analytics and 3rd party conversion tracking solutions in detail. We'll
                                             customize a demo for your needs.
                                        </p>
                                   </div>
                                   <div className="button-wrapper">
                                        <button
                                             className="button"
                                             to={'#'}
                                             style={{ padding: '21px 28px', fontSize: '15px' }}
                                             onClick={() => handleShowSchedule()}
                                        >
                                             Schedule Demo
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          </Layout>
     )
}

export default HealthcareMarketers
